// require("./sass/app.scss");
import docReady from 'doc-ready';

import {lang} from './components/helpers.js'

import Modernizr from 'modernizr';

import 'es6-promise/auto';

require('array.prototype.find').shim();
require('smoothscroll-polyfill').polyfill();

import initConsentManager from "./components/vbcn";
import initNotifications from './components/notifications';

import bindArrowToTop from './components/arrow-to-top';
import bindScrollLinks from './components/scroll-links';

import {initTables} from "./components/tables";


function initGlobal() {

    console.log('Language from global: ' + lang);

    //vbcn
    initConsentManager();

    //GLOBAL
    initNotifications();
    bindArrowToTop();
    bindScrollLinks();

    initTables();
}

export default function () {
    initGlobal();
}

