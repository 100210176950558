"use strict";

import Modernizr from 'modernizr';

export function metalang() {
  let metalang = document.querySelector('html[lang]') ? document.querySelector('html').getAttribute('lang').toLowerCase() : false;
  if (!metalang || metalang.trim() === "") {
    return 'de'
  }
  return metalang;
}

export var lang = metalang();


export var mobilesettings = {
  Utils : {
    PHONE_TAG : "mobile",
    PHABLET_TAG : "phablet",
    TABLET_TAG : "tablet",
    DESKTOP_TAG : "desktop",
    DESKTOP_LARGE_TAG : "wide",
    DESKTOP_ULTRAWIDE_TAG : "ultrawide",
  }
};

// Track breakpoints in JS
export function getResponsiveTag () {
  var tag = window.getComputedStyle(document.body,':after').getPropertyValue('content');
  tag = tag.replace( /"|'/g,'');   // Firefox bugfix
  return tag;
};

export function isMobile () {
  return getResponsiveTag() === mobilesettings.Utils.PHONE_TAG || getResponsiveTag() === mobilesettings.Utils.PHABLET_TAG;
};

export function isTablet () {
  return getResponsiveTag() === mobilesettings.Utils.TABLET_TAG;
};

export function isTouch () {
  return Modernizr.touchevents;
};

export function nodeListToArray (nodelist) {
  return [].slice.call(nodelist);
}

export function swapSVG () {
  if (!Modernizr.svg) {
    let allImages = nodeListToArray(document.querySelectorAll('img'));

    allImages.forEach(function(element) {
      var src = element.attributes.src.value;
      var bgsrc = element.style.backgroundImage;

      if (typeof src !== "undefined") {
        element.attributes.src.value = src.replace(/\.svg$/i, ".png");
      }
      if (typeof bgsrc !== "undefined") {
        element.style.backgroundImage = bgsrc.replace(/\.svg$/i, ".png");
      }
    })
  }
};


export function tableFlip () {
  console.log('(╯°□°）╯︵ ┻━┻');
};

export function  randomIntFromInterval (min,max) {
  return Math.floor(Math.random()*(max-min+1)+min);
};

export function getLayout (layoutContainer) {
  var layout = document.querySelector(layoutContainer).length > 0 ?  document.querySelector(layoutContainer).data('layout') : false;
  return layout;
};

export function getScreenWidth () {
  return window.innerWidth;
};

export function getBaseUrl() {
  var baseurl;
  if (document.querySelector('base')) {
    baseurl = document.querySelector('base').prop('href');
  } else {
    if (window.location.protocol != "https:") {
      baseurl = 'http://' + window.location.hostname;
    } else {
      baseurl = 'https://' + window.location.hostname;
    }
  }
  return baseurl;
}

export function findAncestorByClass (el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls));
  return el;
}

export function addClass(el, cls) {
  if (el.classList.contains(cls)) {
    return
  }
  el.classList.add(cls);
}

export function removeClass(el, cls) {
  if (el.classList.contains(cls)) {
    el.classList.remove(cls);
  }
}

export function hasClass (el, cls) {
    return el.classList.contains(cls)
}

export function toggleClass(el, cls) {
    if (el.classList.contains(cls)) {
      removeClass(el, cls)
    } else {
      addClass(el, cls)
    }
}

export function addStyle(el, styleKey, styleValue) {
    let elStyles = el.style;

    elStyles[styleKey] = styleValue;
}

export function wrapElement(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

export function getWindowScrollPosition() {
  return {
    x: window.pageXOffset || document.documentElement.scrollLeft,
    y: window.pageYOffset || document.documentElement.scrollTop
  };
}

export function scrollTo(element, to, duration) {
    if (duration <= 0) return;
    let difference = to - element.scrollTop;
    let perTick = difference / duration * 10;

    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}

export function encode_utf8(s) {
    return unescape(encodeURIComponent(s));
}

export function decode_utf8(s) {
    return decodeURIComponent(escape(s));
}
/**
 * Fixes SVGs attributes to include the window.location in case a base tag is present
 */
export function fixSVG () {
  /**
   * Current URL, without the hash
   */
  var baseUrl = window.location.href.replace(window.location.hash, "");

  /**
   *  Find all `use` elements with a namespaced `href` attribute, e.g.
   *  <use xlink:href="#some-id"></use>
   *
   *  See: http://stackoverflow.com/a/23047888/796152
   */
  [].slice.call(document.querySelectorAll("use[*|href]"))

  /**
   * Filter out all elements whose namespaced `href` attribute doesn't
   * start with `#` (i.e. all non-relative IRI's)
   *
   * Note: we're assuming the `xlink` prefix for the XLink namespace!
   */
    .filter(function(element) {
      return (element.getAttribute("xlink:href").indexOf("#") === 0);
    })

    /**
     * Prepend `window.location` to the namespaced `href` attribute value,
     * in order to make it an absolute IRI
     *
     * Note: we're assuming the `xlink` prefix for the XLink namespace!
     */
    .forEach(function(element) {
      element.setAttribute("xlink:href", baseUrl + element.getAttribute("xlink:href"));
    });

  /** Clip-path attributes */
  [].slice.call(document.querySelectorAll("svg [clip-path]"))
    .filter(function(element) {
      return (element.getAttribute("clip-path").indexOf("url(#") === 0);
    })
    .forEach(function(element) {
      let oldVal = element.getAttribute("clip-path").slice(4,-1);
      let newVal = "url(" + baseUrl + oldVal +  ")";
      element.setAttribute("clip-path", newVal);
    });

  /** Mask attributes */
  [].slice.call(document.querySelectorAll("svg [mask]"))
    .filter(function(element) {
      return (element.getAttribute("mask").indexOf("url(#") === 0);
    })
    .forEach(function(element) {
      let oldVal = element.getAttribute("mask").slice(4,-1);
      let newVal = "url(" + baseUrl + oldVal +  ")";
      element.setAttribute("mask", newVal);
    });

  /** Pattern attributes */
  [].slice.call(document.querySelectorAll("svg [pattern]"))
    .filter(function(element) {
      return (element.getAttribute("pattern").indexOf("url(#") === 0);
    })
    .forEach(function(element) {
      let oldVal = element.getAttribute("pattern").slice(4,-1);
      let newVal = "url(" + baseUrl + oldVal +  ")";
      element.setAttribute("pattern", newVal);
    });

  /** Fill attributes */
  [].slice.call(document.querySelectorAll("svg [fill]"))
    .filter(function(element) {
      return (element.getAttribute("fill").indexOf("url(#") === 0);
    })
    .forEach(function(element) {
      let oldVal = element.getAttribute("fill").slice(4,-1);
      let newVal = "url(" + baseUrl + oldVal +  ")";
      element.setAttribute("fill", newVal);
    });
}
