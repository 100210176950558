"use strict";

import {findAncestorByClass, addClass, removeClass, hasClass, wrapElement} from './helpers.js';

const defaults = {
    selectors: {
        table: '.content table'
    },
    classes: {
        overFlowLeft: 'has-overflow-left',
        overFlowRight: 'has-overflow-right',
        tableWrapper: 'table-wrapper',
        tableWrapperInner: 'table-wrapper__inner',
    }
};

export function initTables() {
    let tables = document.querySelectorAll(defaults.selectors.table);

    if (tables.length > 0) {

        for (var i = 0; i < tables.length; ++i) {
            checkOverflow(tables[i]);
        }
    }
}

function checkOverflow(table) {
    let tableWidth = table.offsetWidth;
    let parentWidth = table.parentNode.offsetWidth;

    if (parentWidth < tableWidth) {
//    console.log("table is wider than parent");
        wrapTable(table);
        handleOverFlowMarker(table);
        bindScrollHandler(table);
    }
}

function wrapTable(table) {

    // var li = document.createElement('li');
    // var span = document.createElement('span');
    // span.className = 'toggle';
    // span.appendChild(document.createTextNode('Jan'));
    // li.appendChild(span);

    let tableWrapper = document.createElement('div');
    addClass(tableWrapper, defaults.classes.tableWrapper);

    let tableWrapperInner = document.createElement('div');
    addClass(tableWrapperInner, defaults.classes.tableWrapperInner);

    // tableWrapper.appendChild(tableWrapperInner);

    wrapElement(table, tableWrapperInner);
    wrapElement(tableWrapperInner, tableWrapper);
}

function handleOverFlowMarker(table) {
//  console.log("handle overflow marker");
    let tableParent = table.parentNode;
    let tableWidth = table.offsetWidth;
    let parentWidth = tableParent.offsetWidth;

    let scrollOffSet = tableParent.scrollLeft;

    let maxScrollOffset = tableWidth - parentWidth;

    if (scrollOffSet === 0) {
//    console.log("right marker");
        removeLeftOverflowMarker(table);
        addRightOverflowMarker(table);
    }
    if (scrollOffSet > 0 && scrollOffSet < maxScrollOffset) {
//    console.log("both markers");
        addRightOverflowMarker(table);
        addLeftOverflowMarker(table);
    }
    if (scrollOffSet === maxScrollOffset) {
//    console.log("left marker");
        removeRightOverflowMarker(table);
        addLeftOverflowMarker(table);
    }
}

function addLeftOverflowMarker(element) {
    addClass(element,defaults.classes.overFlowLeft);

    // if (element.classList.contains(defaults.classes.overFlowLeft)) {
    //     return
    // }
    // element.classList.add(defaults.classes.overFlowLeft);
}

function addRightOverflowMarker(element) {
    addClass(element,defaults.classes.overFlowRight);

    // if (element.classList.contains(defaults.classes.overFlowRight)) {
    //     return
    // }
    // element.classList.add(defaults.classes.overFlowRight);
}

function removeLeftOverflowMarker(element) {
    removeClass(element, defaults.classes.overFlowLeft);

    // if (element.classList.contains(defaults.classes.overFlowLeft)) {
    //     element.classList.remove(defaults.classes.overFlowLeft);
    // }
}

function removeRightOverflowMarker(element) {
    removeClass(element, defaults.classes.overFlowRight);
    // if (element.classList.contains(defaults.classes.overFlowRight)) {
    //     element.classList.remove(defaults.classes.overFlowRight);
    // }
}

function bindScrollHandler(table) {
    let tableParent = table.parentNode;
    tableParent.addEventListener("scroll", function(){handleOverFlowMarker(table)}, false);
}